import { global } from './../../entity/Affaires/FilterAffaires';
/**
 * Documentation Geoprod
 * Description des web services de Remoteprod
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable, Subject } from 'rxjs';

import { ModelObject } from '../model/models';

import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';
import { environment } from '../../../environments/environment';
import { AffaireDetails, Frais } from 'src/app/entity/Affaires/AffaireDetails';
import { Echeance } from 'src/app/entity/Affaires/Echeancier';
import { Status } from 'src/app/entity/TicketsAndSupport/InformationTicket';
import { StatusEcheance } from 'src/app/pages/affaires/affaire-details/status-echeancier/status-echeancier.component';
import { BankInformation } from 'src/app/entity/BankInformation';
import { MotifAffaire } from 'src/app/pages/affaires/affaire-details/affaire-details.component';
import { bodyLiaison } from 'src/app/pages/affaires/affaire-details/liaisons-contracts/liaisons-contracts.component';

@Injectable()
export class ApiAffairesService {
  protected basePath = environment.api_url;
  public defaultHeaders = new HttpHeaders(); 
  public configuration = new Configuration();
  private _listners$ = new Subject<any>();
  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * sert a affecter une  affaire a l’utilisateur connecté si la relation n’existe pas ou de supprimer l’affectation si elle  existe. Sur l&#x27;IHM il suffit de cliquer sur (*) a coté de l&#x27;affaire dans l&#x27;onglet tous les affaires pour invoker ce web services
   *
   * @param idAffaire Identifiant de l’affaire codé en md5
   * @param idContrat &lt;p&gt;Identifiant de contrat codé en md5&lt;br&gt;c81e728d9d4c2f636f067f89cc14862c  Assurance Auto &lt;br&gt; eccbc87e4b5ce2fe28308fd9f2a7baf3 Assurance Moto&lt;br&gt; 8f14e45fceea167a5a36dedd4bea2543 Protection Juridique &lt;br&gt; c9f0f895fb98ab9159f51fd0297e236d GAV&lt;br&gt; d3d9446802a44259755d38e6d163e820 Santé&lt;br&gt; 6512bd43d9caa6e02c990b0a82652dca Obseques&lt;br&gt; c20ad4d76fe97759aa27a0c99bff6710 Animaux&lt;br&gt; c51ce410c124a10e0db5e4b97fc2af39 RCCANSF&lt;br&gt; aab3238922bcc25a6f606eb525ffdc56 Protection Juridique&lt;br&gt; 9bf31c7ff062936a96d3c8bd1f8f2ff3 Immeubles&lt;br&gt; c74d97b01eae257e44aa9d5bade97baf Multi Pro&lt;br&gt; 70efdf2ec9b086079795c442636b55fb Loyers Impayés&lt;br&gt; 6f4922f45568161a8cdf4ad2299f6d23 PNO  &lt;br&gt; 1f0e3dad99908345f7439f8ffabdffc4 Habitat S &lt;br&gt; 98f13708210194c475687be6106a3b84 Habitat P &lt;br&gt; 3c59dc048e8850243be8079a5c74d079 Véhicule &lt;br&gt; b6d767d2f8ed5d21a44b0e5886680cb9 Dotation &lt;br&gt; 37693cfc748049e45d87b8c7d8b9aacd Epargne - Finance &lt;br&gt; 1ff1de774005f8da13f42943881c655f Retraite &lt;br&gt; 8e296a067a37563370ded05f5a3bf3ec Dépandance &lt;br&gt; 4e732ced3463d06de0ca9a15b6153677 Tempo Décés &lt;br&gt; 02e74f10e0327ad868d138f2b4fdd6f0 Prév-Décés-Inv-IJ &lt;br&gt; e369853df766fa44e1ed0ff613f563bd Santé  collective  Partie Entreprise &lt;br&gt; 1c383cd30b7c298ab50293adfecb7b18 Santé Collective Partie Salarié &lt;br&gt; 19ca14e7ea6328a42e0eb13d585e4c22 IJH &lt;br&gt;  a5bfc9e07964f8dddeb95fc584cd965d  Autres  &lt;br&gt;a5771bce93e200c36f7cd9dfd0e5deaa  Assurance de Prêt&lt;br&gt; les identifiant sont codé respectivement dans la liste deroulante : &lt;/p&gt;
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addFavoriteAffaireGet(
    idAffaire: ModelObject,
    idContrat: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;

  public addFavoriteAffaireGet(
    idAffaire: ModelObject,
    idContrat: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;

  public addFavoriteAffaireGet(
    idAffaire: ModelObject,
    idContrat: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;

  public addFavoriteAffaireGet(
    idAffaire: ModelObject,
    idContrat: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (idAffaire === null || idAffaire === undefined) {
      throw new Error('Required parameter idAffaire was null or undefined when calling addFavoriteAffaireGet.');
    }

    if (idContrat === null || idContrat === undefined) {
      throw new Error('Required parameter idContrat was null or undefined when calling addFavoriteAffaireGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idAffaire !== undefined && idAffaire !== null) {
      queryParameters = queryParameters.set('id_affaire', <any>idAffaire);
    }
    if (idContrat !== undefined && idContrat !== null) {
      queryParameters = queryParameters.set('id_contrat', <any>idContrat);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/add_favorite_affaire`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Permet soit de  supprimer l&#x27;affaire , soit pour modifier le status del&#x27;affaire et ajouter un commentaire (&#x27;Modification des donneés avec changement du statut : A supprimer&#x27;)
   *
   * @param mdp &lt;p&gt;Mot de passe prendre la main soit pour supprimer l&#x27;affaire soit pour modifier le status del&#x27;affaire et ajouter un commentaire (&#x27;Modification des donneés avec changement du statut : A supprimer&#x27;) suivant la mot de passe&lt;/p&gt; &lt;br&gt; A demander par mail a it@sodedif.com
   * @param idAffaire &lt;p&gt;Mot de passe prendre la main soit pour supprimer l&#x27;affaire soit pour modifier le status del&#x27;affaire et ajouter un commentaire (&#x27;Modification des donneés avec changement du statut : A supprimer&#x27;) suivant la mot de passe&lt;/p&gt; &lt;br&gt; A demander par mail a it@sodedif.com
   * @param idUser Identifiant de l&#x27;utilisateur connecté codé en md5 (Obligatoire pour la modification de status)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteAffaireStatGet(
    mdp: ModelObject,
    idAffaire: ModelObject,
    idUser?: ModelObject,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;

  public deleteAffaireStatGet(
    mdp: ModelObject,
    idAffaire: ModelObject,
    idUser?: ModelObject,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;

  public deleteAffaireStatGet(
    mdp: ModelObject,
    idAffaire: ModelObject,
    idUser?: ModelObject,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;

  public deleteAffaireStatGet(
    mdp: ModelObject,
    idAffaire: ModelObject,
    idUser?: ModelObject,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (mdp === null || mdp === undefined) {
      throw new Error('Required parameter mdp was null or undefined when calling deleteAffaireStatGet.');
    }

    if (idAffaire === null || idAffaire === undefined) {
      throw new Error('Required parameter idAffaire was null or undefined when calling deleteAffaireStatGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (mdp !== undefined && mdp !== null) {
      queryParameters = queryParameters.set('mdp', <any>mdp);
    }
    if (idAffaire !== undefined && idAffaire !== null) {
      queryParameters = queryParameters.set('id_affaire', <any>idAffaire);
    }
    if (idUser !== undefined && idUser !== null) {
      queryParameters = queryParameters.set('id_user', <any>idUser);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/delete_affaire_stat`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Permet soit de  supprimer l&#x27;affaire , soit pour modifier le status del&#x27;affaire et ajouter un commentaire (&#x27;Modification des donneés avec changement du statut : A supprimer&#x27;)
   *
   * @param page  nombre des pages
   * @param limit  Nombre des resultats par page
   * @param totale  la somme des résultats
   * @param iSortCol_0  set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param sSortDir_0  set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param filtersStartCreation set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param filtersStartCreation set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listAffairesGet(
    page: number,
    limit: number,
    sSortDir_0: string,
    iSortCol_0: string,
    sSearch: string,
    all_filtres: global,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any> {
    // let sort: string;
    const sort_dict = {
      default: 2,
      '': 2,
      affaire_date_creation: 2,
      prospects_name: 3,
      affaire_mca: 4,
      cmp_denomination: 5,
      affaire_date_deff: 6,
      affaire_produit_contrat: 7,
      contrat_type: 8,
      etat: 9,
    };

    if (page === null || page === undefined) {
      throw new Error('Required parameter filtresEndCreation was null or undefined when calling listAffairesGet.');
    }

    if (limit === null || limit === undefined) {
      throw new Error('Required parameter limit was null or undefined when calling listAffairesGet.');
    }

    if (all_filtres === null || all_filtres === undefined) {
      throw new Error('Required parameter filtresStartCreation was null or undefined when calling listAffairesGet.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    headers = headers.set('Content-Type', 'application/json');

    const request_body = {
      page: page,
      limit: limit,
      iSortCol_0: sort_dict[sSortDir_0],
      sSortDir_0: iSortCol_0,
      sSearch: sSearch,
      all_filtres: all_filtres,
    };
    return this.httpClient.request<any>('post', `${this.basePath}/list_affaires`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      observe: observe,
      headers: headers,
      reportProgress: reportProgress,
      body: request_body,
    });
  }

  /**
   * Sert a afficher le form de contrat et le form de l&#x27;affaire
   *
   * @param createQuittance Identifiant de l&#x27;affaire codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public AjouterQuittance(
    createQuittance: {},
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (createQuittance === null || createQuittance === undefined) {
      throw new Error('Required parameter idAffaire was null or undefined when calling formContratDossierGet.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/create_quittance_affaire?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: createQuittance,
    });
  }

  /**
   * Sert a afficher le form de contrat et le form de l&#x27;affaire
   *
   * @param idAffaire Identifiant de l&#x27;affaire codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public SelectQuittanceAffaire(
    idAffaire: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (idAffaire === null || idAffaire === undefined) {
      throw new Error('Required parameter idAffaire was null or undefined when calling formContratDossierGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idAffaire !== undefined && idAffaire !== null) {
      queryParameters = queryParameters.set('id_affaire', <any>idAffaire);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/select_quittance_affaire?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Sert a afficher le form de contrat et le form de l&#x27;affaire
   *
   * @param idAffaire Identifiant de l&#x27;affaire codé en md5
   * @param idContrat &lt;p&gt;Identifiant de contrat codé en md5&lt;br&gt;c81e728d9d4c2f636f067f89cc14862c  Assurance Auto &lt;br&gt; eccbc87e4b5ce2fe28308fd9f2a7baf3 Assurance Moto&lt;br&gt; 8f14e45fceea167a5a36dedd4bea2543 Protection Juridique &lt;br&gt; c9f0f895fb98ab9159f51fd0297e236d GAV&lt;br&gt; d3d9446802a44259755d38e6d163e820 Santé&lt;br&gt; 6512bd43d9caa6e02c990b0a82652dca Obseques&lt;br&gt; c20ad4d76fe97759aa27a0c99bff6710 Animaux&lt;br&gt; c51ce410c124a10e0db5e4b97fc2af39 RCCANSF&lt;br&gt; aab3238922bcc25a6f606eb525ffdc56 Protection Juridique&lt;br&gt; 9bf31c7ff062936a96d3c8bd1f8f2ff3 Immeubles&lt;br&gt; c74d97b01eae257e44aa9d5bade97baf Multi Pro&lt;br&gt; 70efdf2ec9b086079795c442636b55fb Loyers Impayés&lt;br&gt; 6f4922f45568161a8cdf4ad2299f6d23 PNO  &lt;br&gt; 1f0e3dad99908345f7439f8ffabdffc4 Habitat S &lt;br&gt; 98f13708210194c475687be6106a3b84 Habitat P &lt;br&gt; 3c59dc048e8850243be8079a5c74d079 Véhicule &lt;br&gt; b6d767d2f8ed5d21a44b0e5886680cb9 Dotation &lt;br&gt; 37693cfc748049e45d87b8c7d8b9aacd Epargne - Finance &lt;br&gt; 1ff1de774005f8da13f42943881c655f Retraite &lt;br&gt; 8e296a067a37563370ded05f5a3bf3ec Dépandance &lt;br&gt; 4e732ced3463d06de0ca9a15b6153677 Tempo Décés &lt;br&gt; 02e74f10e0327ad868d138f2b4fdd6f0 Prév-Décés-Inv-IJ &lt;br&gt; e369853df766fa44e1ed0ff613f563bd Santé  collective  Partie Entreprise &lt;br&gt; 1c383cd30b7c298ab50293adfecb7b18 Santé Collective Partie Salarié &lt;br&gt; 19ca14e7ea6328a42e0eb13d585e4c22 IJH &lt;br&gt;  a5bfc9e07964f8dddeb95fc584cd965d  Autres  &lt;br&gt;a5771bce93e200c36f7cd9dfd0e5deaa  Assurance de Prêt&lt;br&gt; les identifiant sont codé respectivement dans la liste deroulante : &lt;/p&gt;
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public formContratDossierGet(
    idAffaire: ModelObject,
    idContrat: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;

  public formContratDossierGet(
    idAffaire: ModelObject,
    idContrat: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;

  public formContratDossierGet(
    idAffaire: ModelObject,
    idContrat: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;

  public formContratDossierGet(
    idAffaire: ModelObject,
    idContrat: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (idAffaire === null || idAffaire === undefined) {
      throw new Error('Required parameter idAffaire was null or undefined when calling formContratDossierGet.');
    }

    if (idContrat === null || idContrat === undefined) {
      throw new Error('Required parameter idContrat was null or undefined when calling formContratDossierGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idAffaire !== undefined && idAffaire !== null) {
      queryParameters = queryParameters.set('id_affaire', <any>idAffaire);
    }
    if (idContrat !== undefined && idContrat !== null) {
      queryParameters = queryParameters.set('id_contrat', <any>idContrat);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/form_contrat_dossier`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Sert a afficher le champ  object_adhesion_html de l’affaire suivant son id codé en md5
   *
   * @param idAffaire Identifiant de l&#x27;affaire codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAdhesionHtmlGet(idAffaire: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public getAdhesionHtmlGet(
    idAffaire: ModelObject,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;

  public getAdhesionHtmlGet(
    idAffaire: ModelObject,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;

  public getAdhesionHtmlGet(
    idAffaire: ModelObject,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (idAffaire === null || idAffaire === undefined) {
      throw new Error('Required parameter idAffaire was null or undefined when calling getAdhesionHtmlGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idAffaire !== undefined && idAffaire !== null) {
      queryParameters = queryParameters.set('id_affaire', <any>idAffaire);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_adhesion_html`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Sert a afficher le champ  object_adhesion de l’affaire suivant son id codé en md5
   *
   * @param idAffaire Identifiant de l&#x27;affaire codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAdhesionObjGet(idAffaire: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public getAdhesionObjGet(
    idAffaire: ModelObject,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;

  public getAdhesionObjGet(
    idAffaire: ModelObject,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;

  public getAdhesionObjGet(
    idAffaire: ModelObject,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (idAffaire === null || idAffaire === undefined) {
      throw new Error('Required parameter idAffaire was null or undefined when calling getAdhesionObjGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idAffaire !== undefined && idAffaire !== null) {
      queryParameters = queryParameters.set('id_affaire', <any>idAffaire);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_adhesion_obj`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  public SelectTicketsAffaires(
    idAffaire: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (idAffaire === null || idAffaire === undefined) {
      throw new Error('Required parameter idAffaire was null or undefined when calling formContratDossierGet.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_ticket_affaire/${idAffaire}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  public SelectTicketsOpportunites(
    idOpportunite: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (idOpportunite === null || idOpportunite === undefined) {
      throw new Error('Required parameter idOpportunite was null or undefined when calling formContratDossierGet.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_ticket_id_opp/${idOpportunite}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Sert a afficher le nom , le prenom de prospcet , l&#x27;identifiant , le type , le numero de contrat et le status de l&#x27;affaire
   *
   * @param id Identifiant de l&#x27;affaire codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProspectAffaireDoublon(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getAffaireDoubleGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (id !== undefined && id !== null) {
      queryParameters = queryParameters.set('id_affaire', <any>id);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_prospect_doublon_affaire?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Sert a afficher le nom , le prenom de prospcet , l&#x27;identifiant , le type , le numero de contrat et le status de l&#x27;affaire
   *
   * @param id Identifiant de l&#x27;affaire codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAffaireDoubleGet(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getAffaireDoubleGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (id !== undefined && id !== null) {
      queryParameters = queryParameters.set('id', <any>id);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_affaire_double`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Sert a afficher le nom , le prenom de prospcet , l&#x27;identifiant , le type , le numero de contrat et le status de l&#x27;affaire
   *
   * @param id Identifiant de l&#x27;affaire codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAffaireGet(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getAffaireDoubleGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (id !== undefined && id !== null) {
      queryParameters = queryParameters.set('id_affaire', <any>id);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_affaire?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Afficher des information sur  le dossier suivant l&#x27;etat dossier
   *
   * @param idAffaire Identifiant de l&#x27;affaire codé en md5
   * @param idContrat &lt;p&gt;Identifiant de contrat codé en md5&lt;br&gt;c81e728d9d4c2f636f067f89cc14862c  Assurance Auto &lt;br&gt; eccbc87e4b5ce2fe28308fd9f2a7baf3 Assurance Moto&lt;br&gt; 8f14e45fceea167a5a36dedd4bea2543 Protection Juridique &lt;br&gt; c9f0f895fb98ab9159f51fd0297e236d GAV&lt;br&gt; d3d9446802a44259755d38e6d163e820 Santé&lt;br&gt; 6512bd43d9caa6e02c990b0a82652dca Obseques&lt;br&gt; c20ad4d76fe97759aa27a0c99bff6710 Animaux&lt;br&gt; c51ce410c124a10e0db5e4b97fc2af39 RCCANSF&lt;br&gt; aab3238922bcc25a6f606eb525ffdc56 Protection Juridique&lt;br&gt; 9bf31c7ff062936a96d3c8bd1f8f2ff3 Immeubles&lt;br&gt; c74d97b01eae257e44aa9d5bade97baf Multi Pro&lt;br&gt; 70efdf2ec9b086079795c442636b55fb Loyers Impayés&lt;br&gt; 6f4922f45568161a8cdf4ad2299f6d23 PNO  &lt;br&gt; 1f0e3dad99908345f7439f8ffabdffc4 Habitat S &lt;br&gt; 98f13708210194c475687be6106a3b84 Habitat P &lt;br&gt; 3c59dc048e8850243be8079a5c74d079 Véhicule &lt;br&gt; b6d767d2f8ed5d21a44b0e5886680cb9 Dotation &lt;br&gt; 37693cfc748049e45d87b8c7d8b9aacd Epargne - Finance &lt;br&gt; 1ff1de774005f8da13f42943881c655f Retraite &lt;br&gt; 8e296a067a37563370ded05f5a3bf3ec Dépandance &lt;br&gt; 4e732ced3463d06de0ca9a15b6153677 Tempo Décés &lt;br&gt; 02e74f10e0327ad868d138f2b4fdd6f0 Prév-Décés-Inv-IJ &lt;br&gt; e369853df766fa44e1ed0ff613f563bd Santé  collective  Partie Entreprise &lt;br&gt; 1c383cd30b7c298ab50293adfecb7b18 Santé Collective Partie Salarié &lt;br&gt; 19ca14e7ea6328a42e0eb13d585e4c22 IJH &lt;br&gt;  a5bfc9e07964f8dddeb95fc584cd965d  Autres  &lt;br&gt;a5771bce93e200c36f7cd9dfd0e5deaa  Assurance de Prêt&lt;br&gt; les identifiant sont codé respectivement dans la liste deroulante : &lt;/p&gt;
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDocumentObligGet(
    idAffaire: ModelObject,
    idContrat: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;

  public getDocumentObligGet(
    idAffaire: ModelObject,
    idContrat: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;

  public getDocumentObligGet(
    idAffaire: ModelObject,
    idContrat: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;

  public getDocumentObligGet(
    idAffaire: ModelObject,
    idContrat: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (idAffaire === null || idAffaire === undefined) {
      throw new Error('Required parameter idAffaire was null or undefined when calling getDocumentObligGet.');
    }

    if (idContrat === null || idContrat === undefined) {
      throw new Error('Required parameter idContrat was null or undefined when calling getDocumentObligGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idAffaire !== undefined && idAffaire !== null) {
      queryParameters = queryParameters.set('id_affaire', <any>idAffaire);
    }
    if (idContrat !== undefined && idContrat !== null) {
      queryParameters = queryParameters.set('id_contrat', <any>idContrat);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_document_oblig`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  listendochistorique(): Observable<any> {
    return this._listners$.asObservable();
  }

  pushdocumenthistorique(filterBy: any) {
    this._listners$.next(filterBy);
  }

  public postStatusEcheance(statusEcheance: StatusEcheance) {
    let headers = this.defaultHeaders;

    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('put', `${this.basePath}/echeancier/v1/update_echeance_status`, {
      body: statusEcheance,
      headers: headers,
    });
  }

  /**
   * Sert a afficher le nom , le prenom de prospcet , l&#x27;identifiant , le type , le numero de contrat et le status de l&#x27;affaire
   *
   * @param id Identifiant de l&#x27;affaire codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public GetHistoriqueAffaires(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getAffaireDoubleGet.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_historique_affaire/${id}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  listenLiaisonsContracts(): Observable<any> {
    return this._listners$.asObservable();
  }

  pushLiaisonsContracts(filterBy: any) {
    this._listners$.next(filterBy);
  }

  /**
   * Sert a afficher le nom , le prenom de prospcet , l&#x27;identifiant , le type , le numero de contrat et le status de l&#x27;affaire
   *
   * @param id Identifiant de l&#x27;affaire codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAffaireInformationsContrat(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getAffaireDoubleGet.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/v1/get_contrat_affaire/${id}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Sert a afficher le nom , le prenom de prospcet , l&#x27;identifiant , le type , le numero de contrat et le status de l&#x27;affaire
   *
   * @param id Identifiant de l&#x27;affaire codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getfraisPayementAffaire(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getAffaireDoubleGet.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_frais_paiement_affaire/${id}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Permet d&#x27;afficher l&#x27;historique (id_user,date id_affaire id historique et object) d&#x27;une affaire suivant l&#x27;identifiant de l&#x27;historique
   *
   * @param id Identifiant de l&#x27;historique affaire codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getHistPtfGet(id: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public getHistPtfGet(id: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public getHistPtfGet(id: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public getHistPtfGet(id: ModelObject, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getHistPtfGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (id !== undefined && id !== null) {
      queryParameters = queryParameters.set('id', <any>id);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_hist_ptf`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Sert a afficher la libelle de contrat et l’identifiant de l’affaire suivant l’id de add_prospect et l’id de l’affaire codé en md5
   *
   * @param idAffaire Identifiant de l&#x27;affaire codé en md5
   * @param idProspect identifiant de add_prospect codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public rechercheAffaireProsGet(
    idAffaire: ModelObject,
    idProspect: ModelObject,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;

  public rechercheAffaireProsGet(
    idAffaire: ModelObject,
    idProspect: ModelObject,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;

  public rechercheAffaireProsGet(
    idAffaire: ModelObject,
    idProspect: ModelObject,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;

  public rechercheAffaireProsGet(
    idAffaire: ModelObject,
    idProspect: ModelObject,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (idAffaire === null || idAffaire === undefined) {
      throw new Error('Required parameter idAffaire was null or undefined when calling rechercheAffaireProsGet.');
    }

    if (idProspect === null || idProspect === undefined) {
      throw new Error('Required parameter idProspect was null or undefined when calling rechercheAffaireProsGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idAffaire !== undefined && idAffaire !== null) {
      queryParameters = queryParameters.set('id_affaire', <any>idAffaire);
    }
    if (idProspect !== undefined && idProspect !== null) {
      queryParameters = queryParameters.set('id_prospect', <any>idProspect);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/recherche_affaire_pros`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Definir une  affaire parente par rapport a une a la courante, le web service retourne 1 en cas d&#x27;affectation et 0 si non
   *
   * @param idCourant Identifiant de l&#x27;affaire courante codé en md5
   * @param idAffaire Identifiant de l&#x27;affaire codé en md5 celle a définir parente si ce champs est vide ou invalide l&#x27;affaire parente sera null
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateAffaireParentGet(
    idCourant: ModelObject,
    idAffaire?: ModelObject,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;

  public updateAffaireParentGet(
    idCourant: ModelObject,
    idAffaire?: ModelObject,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;

  public updateAffaireParentGet(
    idCourant: ModelObject,
    idAffaire?: ModelObject,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;

  public updateAffaireParentGet(
    idCourant: ModelObject,
    idAffaire?: ModelObject,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (idCourant === null || idCourant === undefined) {
      throw new Error('Required parameter idCourant was null or undefined when calling updateAffaireParentGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idAffaire !== undefined && idAffaire !== null) {
      queryParameters = queryParameters.set('id_affaire', <any>idAffaire);
    }
    if (idCourant !== undefined && idCourant !== null) {
      queryParameters = queryParameters.set('id_courant', <any>idCourant);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/update_affaire_parent`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Definir une  affaire parente par rapport a une a la courante, le web service retourne 1 en cas d&#x27;affectation et 0 si non
   *
   * @param quittance
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateQuittanceAffaire(
    quittance: {},
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (quittance === null || quittance === undefined) {
      throw new Error('Required parameters quittance was null or undefine');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('put', `${this.basePath}/update_quittance_affaire?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: quittance,
    });
  }

  /**
   * Definir une  affaire parente par rapport a une a la courante, le web service retourne 1 en cas d&#x27;affectation et 0 si non
   *
   * @param quittance
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteQuittanceAffaire(
    quittance: {},
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (quittance === null || quittance === undefined) {
      throw new Error('Required parameters quittance was null or undefine');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('delete', `${this.basePath}/delete_quittance_affaire?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: quittance,
    });
  }

  /**
   * Sert a  creé une relation entre une affaire et une ou plusieurs affaires dans la table vente_combine
   *
   * @param idCourant Identifiant de l&#x27;affaire courante codé en md5
   * @param tab Identifiant de l&#x27;affaire courante codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateAffaireRelationGet(
    idCourant: ModelObject,
    tab: ModelObject,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;

  public updateAffaireRelationGet(
    idCourant: ModelObject,
    tab: ModelObject,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;

  public updateAffaireRelationGet(
    idCourant: ModelObject,
    tab: ModelObject,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;

  public updateAffaireRelationGet(
    idCourant: ModelObject,
    tab: ModelObject,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (idCourant === null || idCourant === undefined) {
      throw new Error('Required parameter idCourant was null or undefined when calling updateAffaireRelationGet.');
    }

    if (tab === null || tab === undefined) {
      throw new Error('Required parameter tab was null or undefined when calling updateAffaireRelationGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idCourant !== undefined && idCourant !== null) {
      queryParameters = queryParameters.set('id_courant', <any>idCourant);
    }
    if (tab !== undefined && tab !== null) {
      queryParameters = queryParameters.set('tab', <any>tab);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/update_affaire_relation`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Ce web service permet de mettre a jour le groupe action d&#x27; une liste des affaires avec une liste des tags a changer par
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateGroupActionAffGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
  public updateGroupActionAffGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public updateGroupActionAffGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public updateGroupActionAffGet(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/update_group_action_aff`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  public allDerogation(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_list_derogation`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  // public getLiaisonContrat(
  //   idAffaire1: string,
  //   idAffaire2: string,
  //   observe: any = 'body',
  //   reportProgress: boolean = false
  // ): Observable<any> {
  //   if (idAffaire1 === null || idAffaire1 === undefined) {
  //     throw new Error('Required parameter idAffaire was null or undefined when calling getLiaisonContrat.');
  //   }
  //   if (idAffaire2 === null || idAffaire2 === undefined) {
  //     throw new Error('Required parameter idAffaire was null or undefined when calling getLiaisonContrat.');
  //   }

  //   const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

  //   let headers = this.defaultHeaders;

  //   // authentication (Acess_Token) required
  //   if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
  //     headers = headers.set('idSession', this.configuration.apiKeys.idSession);
  //   }

  //   // to determine the Accept header
  //   const httpHeaderAccepts: string[] = [];
  //   const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
  //   if (httpHeaderAcceptSelected !== undefined) {
  //     headers = headers.set('Accept', httpHeaderAcceptSelected);
  //   }

  //   return this.httpClient.request<any>('get', `${this.basePath}/liaison_contrat_affaire/${idAffaire1}/${idAffaire2}`, {
  //     params: queryParameters,
  //     withCredentials: this.configuration.withCredentials,
  //     headers: headers,
  //     observe: observe,
  //     reportProgress: reportProgress,
  //   });
  // }

  public getLiaisonContrat(
    liaison: bodyLiaison,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('put', `${this.basePath}/affaire/add_relation`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: liaison,
    });
  }

  public UpdateAffaire(
    id: string,
    affaireDetails: AffaireDetails,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('put', `${this.basePath}/v1/update_affaire/${id}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: affaireDetails,
    });
  }

  public UpdateInfoBancaire(
    id: string,
    bankDetails: BankInformation[],
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('put', `${this.basePath}/update_info_bancaire_affaire/${id}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: bankDetails,
    });
  }

  public DeleteInfoBancaire(
    id: string,
    bankDetails: any,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('delete', `${this.basePath}/info_bancaire/delete/${id}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: bankDetails,
    });
  }

  public sendMail(formData: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (formData === null || formData === undefined) {
      throw new Error('Required parameter idOpp was null or undefined when calling addDocumentOpportuniteGet.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/webmail/send_mail_by_user_config?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
      body: formData,
    });
  }

  public GetEchancierAffaire(
    idAffaire: string,
    limit: any,
    page: any,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (idAffaire === null || idAffaire === undefined) {
      throw new Error('Required parameter idAffaire was null or undefined when calling getEchancierAffaire.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    queryParameters = queryParameters.set('limit', <any>limit);
    queryParameters = queryParameters.set('page', <any>page);
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/echeancier/v1/get_by_affaire/${idAffaire}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  private reloadSoldeEcheancier = new Subject<void>();
  reloadSoldeEcheancier$ = this.reloadSoldeEcheancier.asObservable();

  NotifyreloadSoldeEcheancier() {
    this.reloadSoldeEcheancier.next();
  }

  public GetTransactionsAffaire(
    idAffaire: string,
    limit: any,
    page: any,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (idAffaire === null || idAffaire === undefined) {
      throw new Error('Required parameter idAffaire was null or undefined when calling GetTransactionsAffaire.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }
    if (idAffaire !== undefined && idAffaire !== null) {
      queryParameters = queryParameters.set('id_affaire', <any>idAffaire);
    }
    queryParameters = queryParameters.set('limit', <any>limit);
    queryParameters = queryParameters.set('page', <any>page);
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/affaire/get_mouvement_financiere`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  public GetListEcheances(idEcheance: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (idEcheance === null || idEcheance === undefined) {
      throw new Error('Required parameter idAffaire was null or undefined when calling getEchancierAffaire.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/echeancier/v1/echeances/${idEcheance}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  public allStatutFrais(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_list_statut_frais?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  public allTypeFrais(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_list_types_frais?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  public allTypeFraisTicket(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_list_types_frais_ticket?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  public InsertFrais(
    idAffaire: string,
    frais: Frais,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (idAffaire === null || idAffaire === undefined) {
      throw new Error('Required parameter idAffaire was null or undefined when calling getLiaisonContrat.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idAffaire !== undefined && idAffaire !== null) {
      queryParameters = queryParameters.set('id_affaire', <any>idAffaire);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/insert_frais_affaire/${idAffaire}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: frais,
    });
  }

  listenTickets(): Observable<any> {
    return this._listners$.asObservable();
  }

  pushTickets(filterBy: any) {
    this._listners$.next(filterBy);
  }

  /**
   * Affiche les informations spécifiques liées à une Affaire ainsi que la form et les indications du contrat
   *
   */
  public getInfoSpecifiqueAffaireGet(
    id_affaire: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id_affaire === null || id_affaire === undefined) {
      throw new Error('Required parameter id_affaire was null or undefined when calling getLiaisonContrat.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (id_affaire !== undefined && id_affaire !== null) {
      queryParameters = queryParameters.set('id_affaire', <any>id_affaire);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_info_specifique/${id_affaire}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  public multipleActionAffaire(
    statutBody: any,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/multiple_action_affaire`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: statutBody,
    });
  }

  public addAffaireGarantie(
    bodyAffaireGarantie: {},
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/garantie/add_affaire_garantie?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: bodyAffaireGarantie,
    });
  }

  public getAffaireGarantieByID(
    id_affaire: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id_affaire === null || id_affaire === undefined) {
      throw new Error('Required parameter id_affaire was null or undefined when calling getLiaisonContrat.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/garantie/get_affaire_garantie/${id_affaire}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  public getAffaireGarantieByIDV1(
    id_affaire: string,
    limit: any,
    page: any,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id_affaire === null || id_affaire === undefined) {
      throw new Error('Required parameter id_affaire was null or undefined when calling getLiaisonContrat.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    // queryParameters = queryParameters.set('limit', <any>limit);
    // queryParameters = queryParameters.set('page', <any>page);
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/garantie/v2/get_affaire_garantie/${id_affaire}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  public getListeStatusAffaireGarantie(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/garantie/get_status_garantie`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  public updateAffaireGarantie(
    bodyAffaireGarantie: {},
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('put', `${this.basePath}/garantie/update_affaire_garantie?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: bodyAffaireGarantie,
    });
  }

  public generateQuitance(
    id_affaire: string,
    id_frais: string,
   


    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {

    if (id_affaire === null || id_affaire === undefined) {
      throw new Error('Required parameter id_affaire was null or undefined when calling getLiaisonContrat.');
    }
    

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (id_affaire !== undefined && id_affaire !== null) {
      queryParameters = queryParameters.set('id_affaire', <any>id_affaire);
    }
    if (id_frais) {
      queryParameters = queryParameters.set('id_frais', <any>id_frais);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/frais/generate_quittance`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  public generateQuitanceEcheancier(
    id_Echeancier: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
   

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (id_Echeancier !== undefined && id_Echeancier !== null) {
      queryParameters = queryParameters.set('id_echeancier', <any>id_Echeancier);
    }
    

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/echeancier/generate_quittance`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  public generateQuitanceEcheance(
    id_Echeance: string,
    id_Echeancier: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
  
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
  
    if (id_Echeance !== undefined && id_Echeance !== null) {
      queryParameters = queryParameters.set('id_echeance', <any>id_Echeance);
    }
    if (id_Echeancier !== undefined && id_Echeancier !== null) {
      queryParameters = queryParameters.set('id_echeancier', <any>id_Echeancier);
    }
    
    
    let headers = this.defaultHeaders;
  
    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }
  
    // to determine the Accept header, provide default options if needed
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
  
    return this.httpClient.request<any>('post', `${this.basePath}/echeancier/echeance/generate_quittance`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }
  

  public generateQuitanceTransaction(
    id_transaction: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    if (id_transaction) {
      queryParameters = queryParameters.set('id_transaction', id_transaction);
    }

    let headers = this.defaultHeaders;

    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/mouvement_financier/generate_quittance`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }
  
  public getlistStatutEcheances(): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    return this.httpClient.request<any>('get', environment.api_url + `/echeancier/list_status_echeance`, {
      headers,
    });
  }

  public CreerAvenant(avenantBody: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/affaire/avenant/initialize?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: avenantBody,
    });
  }

  public CreerAvenantLocal(avenantBody: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/affaire/avenant/create?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: avenantBody,
    });
  }


  public getListeFractionnement(): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    return this.httpClient.request<any>('get', environment.api_url + `/get_fractionnement`, {
      headers,
    });
  }

  public getListeSendingMode(): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    return this.httpClient.request<any>('get', environment.api_url + `/SendingMode`, {
      headers,
    });
  }



  public getlistFormeJuridique(): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    return this.httpClient.request<any>('get', environment.api_url + `/get_formes_juridiques`, {
      headers,
    });
  }

  public getlistMotifAvenant(): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    return this.httpClient.request<any>('get', environment.api_url + `/affaire/avenant/list_motif`, {
      headers,
    });
  }

  public getlistBpmAffaire(id_affaire: any): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    queryParameters = queryParameters.set('id_aff', <any>id_affaire);

    return this.httpClient.request<any>('get', environment.api_url + `/affaire/get_current_services`, {
      headers: headers,
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
    });
  }

  public updateEtatDossierMotif(
    bodyMotif: MotifAffaire,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('put', `${this.basePath}/affaire/update_motif?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: bodyMotif,
    });
  }

  public getAffaireQuality(
    id_affaire: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id_affaire === null || id_affaire === undefined) {
      throw new Error('Required parameter id_affaire was null or undefined when calling getLiaisonContrat.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_affaire_qualite/${id_affaire}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  public UpdateQualityAffaire(
    idAffaire: string,
    data: any,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (idAffaire === null || idAffaire === undefined) {
      throw new Error('Required parameter idAffaire was null or undefined when calling getLiaisonContrat.');
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('put', `${this.basePath}/update_affaire_qualite/${idAffaire}`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: data,
    });
  }

  public updateStatusFrais(id_frais: number, id_status: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ) {
  
    let headers = this.defaultHeaders;
  
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }
  
    headers = headers.set('Content-Type', 'application/json');
  
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
  
    return this.httpClient.request<any>('put', `${this.basePath}/frais/update/${id_frais}`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: { id_status: id_status }, 
    });
  }
  
  

}
 